export const CREATE_JOB_LISTING = gql`
  mutation createJobListing($input: CreateJobListingInput!) {
    createJobListing(input: $input) {
      jobPostingDate
      status
      title
      address {
        street
        city
        state
        country
        zipCode
      }
    }
  }
`

export const UPDATE_JOB_LISTING = gql`
  mutation updateJobListing($id: String!, $input: UpdateJobListingInput!) {
    updateJobListing(id: $id, input: $input) {
      jobPostingDate
      status
      title
      address {
        street
        city
        state
        country
        zipCode
      }
      employees {
        employeeId
        firstName
        lastName
      }
    }
  }
`

export const DELETE_JOB_LISTING = gql`
  mutation deleteJobListing($id: String!) {
    deleteJobListing(id: $id) {
      title
    }
  }
`

export const GET_JOB_LISTINGS = gql`
  query getJobListings(
    $filters: JobListingFiltersInput
    $pageInfo: PageInfoInput
  ) {
    jobListings(filters: $filters, pageInfo: $pageInfo) {
      items {
        jobListingId
        createdAt
        updatedAt
        jobPostingDate
        status
        title
        employees {
          employeeId
          firstName
          lastName
          roleType
          coverImage
        }
        address {
          street
          city
          state
          country
          zipCode
        }
        trackingLinks {
          trackingLinkId
          linkType
          linkName
        }
      }
      pageInfo {
        offset
        limit
      }
      totalCount
    }
  }
`

export const GET_JOB_LISTINGS_ONLY = gql`
  query getJobListingsOnly(
    $filters: JobListingFiltersInput
    $pageInfo: PageInfoInput
  ) {
    jobListings(filters: $filters, pageInfo: $pageInfo) {
      items {
        jobListingId
        createdAt
        updatedAt
        jobPostingDate
        status
        title
      }
      pageInfo {
        offset
        limit
      }
      totalCount
    }
  }
`
